import {
  STUDIES_LOADING,
  STUDIES_SET_DATA,
  STUDIES_ERROR,
} from "./constants";

const initialState = {
  loading: false,
  errorMessage: {},
  studies: []
};

export function studyReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case STUDIES_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case STUDIES_ERROR:
      return {
        ...state,
        errorMessage: action.payload
      };
    case STUDIES_SET_DATA:
      return {
        ...state,
        studies: action.payload
      };
    default:
      return state;
  }
}
