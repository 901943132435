import React, {useEffect} from "react";
import "./WaitingAuth.css";
import { removeToken, removeRole } from "../../../utils/authManager";
import { connect } from "react-redux";

const WaitingAuth = (props) => {
  const handleLogout = async () => {
    await removeToken();
    await removeRole();
    props.history.push("/");
  };
  const loadData = async () => {
    if (props.profile.profile) {
      if (props.profile.profile.status === "approved") {
        props.history.push("/dashboard");
        return;
      }
    }
  };
  useEffect(() => {
    loadData();
  }, [props.profile]);
  return (
    <div className="waiting d-flex flex-column justify-content-center align-items-center container-fluid">
      <h5 className="my-0 mr-md-auto font-weight-normal font-weight-bold text-dark m-5 waitting_logo">
        <img src="/images/logo/logo.png" alt="" className="logo" />
        Tune In Psychedelics
      </h5>
      <img src="/images/illustrations/w1.gif" className='waitting_img' />
      <h1 className="waiting-msg text-center "> Your account is waiting for approval from admin </h1>
      <button className="btn btn-danger" onClick={()=>handleLogout()}>Logout</button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
});
export default connect(mapStateToProps, {})(WaitingAuth);