import {
  PRODUCTS_LOADING,
  PRODUCTS_SET_DATA,
  PRODUCTS_ERROR,
} from "./constants";

const initialState = {
  loading: false,
  errorMessage: {},
  products: []
};

export function productReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PRODUCTS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case PRODUCTS_ERROR:
      return {
        ...state,
        errorMessage: action.payload
      };
    case PRODUCTS_SET_DATA:
      return {
        ...state,
        products: action.payload
      };
    default:
      return state;
  }
}
