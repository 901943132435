import React, { useState } from "react";
import { connect } from "react-redux";
import { createWebinar } from "../../../store/webinars/actions";
import $ from "jquery";

const CreateForm = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [link, setLink] = useState("");
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    // const [image, setImage] = useState(null);
    const [dateOfEvent, setDateOfEvent] = useState(new Date());

    const handleCreateWebinarFeed = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            let formData = new FormData();
            formData.append("link", link);
            formData.append("name", name);
            formData.append("desc", desc);
            formData.append("date_of_event", dateOfEvent);
            // formData.append("image", image);

            await props.createWebinar(formData);
            $('#webinarCreateModal').modal('hide');
            setLink("");
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }
    return (
        <div className="modal fade" id="webinarCreateModal" tabindex="-1" role="dialog" aria-labelledby="webinarCreateModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form action="" onSubmit={handleCreateWebinarFeed}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="webinarCreateModalLabel">Add webinars feed</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <input type="text" className="form-control mb-3" placeholder="Enter name" required value={name} onChange={(e) => setName(e.target.value)} />
                            <input type="text" className="form-control mb-3" placeholder="Enter affiliate link" required value={link} onChange={(e) => setLink(e.target.value)} />
                            <textarea className="form-control mb-3" placeholder="Enter description" value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
                            <input type="datetime-local" className="form-control mb-3" placeholder="Enter event date" value={dateOfEvent} onChange={(e) => setDateOfEvent(e.target.value)}/>
                            {/* <input
                                type="file"
                                id="up"
                                className="form-control"
                                onChange={(e) => setImage(e.target.files[0])}
                                accept=".jpg,.jpeg,.png,.gif"
                                required
                            /> */}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" type="submit" disabled={isLoading}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
    createWebinar
})(CreateForm);