import axios from "axios";
import { BASE_URL } from "../../config";
import { getToken } from "../../utils/authManager";
export function _fetchProfile() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}AdsUser/profile`,{
        headers:{
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}