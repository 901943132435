import axios from "axios";
import { BASE_URL } from "../../config";
import { setToken, setRole } from "../../utils/authManager";
export function loginWithPassword(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}AdsAuth/login`, formData)
      .then(async (response) => {
        await setToken(response.data.token);
        await setRole(response.data.role)
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _register(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}AdsAuth/register`, formData)
      .then(async (response) => {
        await setToken(response.data.token);
        await setRole(response.data.role)
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}