import React, { useState, useEffect } from "react";
import "./Posts.css";
import { Link } from "react-router-dom";
import { FiExternalLink, FiAperture } from "react-icons/fi";
import styled from "styled-components";
import { connect } from "react-redux";
import { fetchAllAds, changeStatus } from "../../store/media/actions";
import Pagination from "react-js-pagination";
import { UPLOAD_URL } from "../../config/index";
import Swal from "sweetalert2";
const Posts = (props) => {
  const [state, setState] = useState({
    activePagePending: 1,
    limitPending: 20,
    offsetPending: 0,
    activePageApproved: 1,
    limitApproved: 20,
    offsetApproved: 0,
    media: {},
  });
  useEffect(() => {
    props.fetchAllAds({
      status: 0,
      limit: state.limitPending,
      offset: state.offsetPending,
    });
    props.fetchAllAds({
      status: 1,
      limit: state.limitApproved,
      offset: state.offsetApproved,
    });
  }, []);
  const handlePageChange = (pageNumber, status) => {
    const { limitApproved, limitPending } = state;
    if (status === 0) {
      let newOffset = (pageNumber - 1) * limitPending;
      props.fetchAllAds({ status: 0, limit: limitPending, offset: newOffset });
      setState({
        ...state,
        activePagePending: pageNumber,
        offsetPending: newOffset,
      });
    } else {
      let newOffset = (pageNumber - 1) * limitApproved;
      props.fetchAllAds({ status: 0, limit: limitApproved, offset: newOffset });
      setState({
        ...state,
        activePageApproved: pageNumber,
        offsetApproved: newOffset,
      });
    }
  };
  const handleChangeStatus = async (id, status) => {
    await props.changeStatus({
      id,
      status,
    });
    props.fetchAllAds({
      status: 0,
      limit: state.limitPending,
      offset: state.offsetPending,
    });
    props.fetchAllAds({
      status: 1,
      limit: state.limitApproved,
      offset: state.offsetApproved,
    });
    if (status) Swal.fire(`Media approved successfully`, "", "success");
    else Swal.fire(`Media declined successfully`, "", "success");
  };
  return (
    <div className="admin-Posts">
      <h1 className="py-2 border-bottom d-flex justify-content-start align-items-center mb-3 text-header-sm">
        <FiAperture className="mr-3" /> Posts
      </h1>
      <ul
        className="nav nav-pills justify-content-center mb-3 "
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item">
          <a
            className="nav-link active text-sm"
            id="pills-home-tab"
            data-toggle="pill"
            href="#pills-Need-Verification"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Need Verification
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link text-sm"
            id="pills-profile-tab"
            data-toggle="pill"
            href="#pills-Verified"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            Verified
          </a>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-Need-Verification"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div className="d-flex justify-content-center align-items-center">
            <div className="w-100 bg-light">
              {props.loading && (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {props.pendingMedia.length === 0 && !props.loading && (
                <p style={{ fontSize: 20, textAlign: "center" }}>
                  No new application found!
                </p>
              )}
              {props.pendingMedia.map((item, i) => (
                <div
                  className="user bg-white rounded shadow-sm p-2 my-3 mx-2 "
                  key={i}
                >
                  <div className="d-flex justify-content-start flex-column flex-sm-row align-items-start align-items-sm-center">
                    <div className="d-flex justify-content-start align-items-center border-right  flex-1mb-2 mb-sm-0 border-mobile-0 ">
                      <img
                        src="/images/logo/avatar.png"
                        alt=""
                        className="img-fluid img-thumbnail rounded-circle img-sqare"
                      />
                      <div className="h5 font-weight-bold mx-3">
                        {item.name}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
                      <div className="h6 font-weight-normal mx-3">
                        {item.business_name}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
                      <div className="h6 font-weight-normal mx-3">
                        Impressions: {item.impressions}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
                      <div className="h6 font-weight-normal mx-3">
                        Clicks: {item.click}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
                      <div className="h6 font-weight-bold font-italic mx-3">
                        {item.title}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center flex-row align-items-center flex-1 mb-2 mb-sm-0 border-mobile-0 ">
                      <button
                        className="btn btn-outline-success mr-1 ml-3 ml-sm-0"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={() => setState({ ...state, media: item })}
                      >
                        View More
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleChangeStatus(item.id, 1)}
                      >
                        Activate
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {props.pendingMedia.length > 0 && (
            <div className="d-flex justify-content-center align-items-center flex-column">
              <Pagination
                activePage={state.activePagePending}
                itemsCountPerPage={state.limitPending}
                totalItemsCount={props.pendingMediaCount}
                pageRangeDisplayed={5}
                onChange={(count) => handlePageChange(count, 0)}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          )}
        </div>
        <div
          className="tab-pane fade"
          id="pills-Verified"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <div className="d-flex justify-content-center align-items-center">
            <div className="w-100 bg-light">
              {props.loading && (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {props.approvedMedia.length === 0 && !props.loading && (
                <p style={{ fontSize: 20, textAlign: "center" }}>
                  No verified application found!
                </p>
              )}
              {props.approvedMedia.map((item, i) => (
                <div
                  className="user bg-white rounded shadow-sm p-2 my-3 mx-2 "
                  key={i}
                >
                  <div className="d-flex justify-content-start flex-row align-items-flex-start">
                    <div className="d-flex justify-content-start align-items-center border-right  flex-1mb-2 mb-sm-0 border-mobile-0  ">
                      <img
                        src="/images/logo/avatar.png"
                        alt=""
                        className="img-fluid img-thumbnail rounded-circle img-sqare"
                      />
                      <div className="h5 font-weight-bold mx-3">
                        {item.name}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0  ">
                      <div className="h6 font-weight-normal mx-3">
                        {item.business_name}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
                      <div className="h6 font-weight-normal mx-3">
                        Impressions: {item.impressions}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
                      <div className="h6 font-weight-normal mx-3">
                        Clicks: {item.click}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0  ">
                      <div className="h6 font-weight-bold font-italic mx-3">
                        {item.title}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center flex-row align-items-center flex-1 mb-2 mb-sm-0 border-mobile-0  ">
                      <button
                        className="btn btn-outline-success mr-2 ml-3 ml-sm-0"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={() => setState({ ...state, media: item })}
                      >
                        View More
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleChangeStatus(item.id, 0)}
                      >
                        Deactivate
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {props.approvedMedia.length > 0 && (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Pagination
                        activePage={state.activePageApproved}
                        itemsCountPerPage={state.limitApproved}
                        totalItemsCount={props.approvedMediaCount}
                        pageRangeDisplayed={5}
                        onChange={(count) => handlePageChange(count, 1)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  )}
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Complete Post
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-between align-items-center my-3 border-bottom pb-3">
                <div className="h3">{state.media?.title}</div>
                <div className="d-flex">
                  <a
                    className="btn btn-outline-primary"
                    href={(state.media?.link?.indexOf('://') === -1) ? 'http://' + state.media?.link : state.media?.link}
                    target="_blank"
                  >
                    <FiExternalLink className="mr-2" />
                    Banner Link
                  </a>
                </div>
              </div>
              <div className="bg-light p-3 shadow-sm rounded">
                <div className="h5">Landscape mode</div>
                {/* <Banner className="jumbotron main-jumbo bg-transparent"></Banner> */}
                <img
                  src={`${UPLOAD_URL}${state.media?.landscape_media}`}
                  alt="Landscape Image"
                  className="w-100 m-auto"
                />
                <div className="h5">Potrait mode</div>
                {/* <BannerSQ className="jumbotron main-jumbo-sqare mx-auto"></BannerSQ> */}
                <img
                  src={`${UPLOAD_URL}${state.media?.portrait_media}`}
                  alt="Potrait Image"
                  className="w-100 m-auto"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">
                Activate
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {/*  */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  loading: state.media.loading,
  pendingMedia: state.media.pendingMedia,
  pendingMediaCount: state.media.pendingMediaCount,
  approvedMedia: state.media.approvedMedia,
  approvedMediaCount: state.media.approvedMediaCount,
});
export default connect(mapStateToProps, {
  fetchAllAds,
  changeStatus,
})(Posts);

const Banner = styled.div`
  background-image: url(/images/bg/banner.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const BannerSQ = styled.div`
  background-image: url(/images/bg/banner2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
