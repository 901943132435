import {
  PROFILE_LOADING,
  PROFILE_SET_DATA,
  PROFILE_ERROR,
} from "./constants";
import { _fetchProfile } from "./services";
import { errorParser } from '../../services/apiErrorParser';

/* Profile actions */
export function profileSetLoading(loading) {
  return {
    type: PROFILE_LOADING,
    payload: loading,
  };
}

export function profileSetData(data) {
  return {
    type: PROFILE_SET_DATA,
    payload: data,
  };
}

export function profileSetError(error) {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
}

export const fetchProfile = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(profileSetLoading(true));
		_fetchProfile().then(async (res) => {
			await dispatch(profileSetData(res));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(profileSetError(parsedError));
			reject(err)
		}).finally(() => {
			dispatch(profileSetLoading(false));
		})
	})
};