import React, {useEffect} from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { isLogin, getRole, removeToken, removeRole } from "../../utils/authManager";
import { connect } from "react-redux";
import { fetchProfile } from "../../store/profile/actions";

const RouteWithLayout = (props) => {
  var history = useHistory();
  const {
    layout: Layout,
    component: Component,
    restricted,
    access,
    scope,
    ...rest
  } = props;
  useEffect(()=>{
    props.fetchProfile()
  }, [])
  const handleLogout = async () => {
    await removeToken();
    await removeRole();
    history.push("/");
  };
  useEffect(()=>{
    if(props.profile.hasOwnProperty("profile")){
      if(props.profile?.profile?.status === "rejected"){
        handleLogout()
      }
    }
  }, [props.profile])
  if (access === "public")
    return (
      <Route
        {...rest}
        render={(matchProps) =>
          isLogin() && restricted ? (
            getRole() === "admin" ? (
              <Redirect to="/admin/users" />
            ) : (
              <Redirect to="/dashboard" />
            )
          ) : (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          )
        }
      />
    );
  else if (access === "private")
    return (
      <Route
        {...rest}
        render={(matchProps) =>
          isLogin() ? (
            getRole() === scope ? (
              <Layout>
                <Component {...matchProps} />
              </Layout>
            ) : scope === "subscriber" ? (
              <Redirect to="/admin/users" />
            ) : (
              <Redirect to="/dashboard" />
            )
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
};

const mapStateToProps = (state) => ({
  loading: state.profile.loading,
  profile: state.profile.profile
});
export default connect(mapStateToProps, {
  fetchProfile,
})(RouteWithLayout);
