import React from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { removeToken, removeRole } from "../../../utils/authManager";

const HeaderAdmin = (props) => {
  var history = useHistory();
  const handleLogout = async () => {
    await removeToken();
    await removeRole();
    history.push("/");
  };
  return (
    <div className="d-flex flex-row flex-md-row justify-content-between align-items-center p-2 p-md-3 px-md-4 mb-3 bg-white border-bottom box-shadow fixed-top  ">
      <div className="my-0 mr-md-auto text-decoration-none d-flex justify-content-start align-items-center">
        <FiMenu className="menu " onClick={() => props.toggleSidebar()} />
        <Link to="/dashboard" className="text-decoration-none">
          <h5 className="my-0 mr-md-auto font-weight-bold text-white color-violet">
            <img src="/images/logo/logo.png" alt="" className="logo" />
            <span className="d-none d-sm-inline"> Tune In Psychedelics</span>
          </h5>
        </Link>
      </div>
      <button className="btn btn-gradient btn-small" onClick={()=>handleLogout()}>
        Log out
      </button>
    </div>
  );
};

export default HeaderAdmin;
