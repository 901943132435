import React, {useState, useEffect} from "react";
import "./UsersAdmin.css";
import { FiUsers } from "react-icons/fi";
import { connect } from "react-redux";
import { fetchUsers, changeStatus } from "../../store/users/actions";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import {Link, Redirect} from "react-router-dom";

const UsersAdmin = (props) => {
  const [state, setState] = useState({
    activePage: 1,
    limit: 20,
    offset: 0,
  });
  useEffect(() => {
    props.fetchUsers({
      status: "approved",
      limit: state.limit,
      offset: state.offset,
    });
  }, []);
  const handlePageChange = (pageNumber) => {
    const { limit } = state;
    let newOffset = (pageNumber - 1) * limit;
    props.fetchUsers({ status: "approved", limit, offset: newOffset });
    setState({
      ...state,
      activePage: pageNumber,
      offset: newOffset,
    });
  };
  const handleChangeStatus = async (user_id, status) => {
    await props.changeStatus({
      id: user_id,
      status,
    });
    Swal.fire(`User ${status} successfully`, "", "success");
  };
  return (
    <div className="admin-users">
      <h1 className="py-2 border-bottom d-flex justify-content-start align-items-center text-header-sm">
        <FiUsers className="mr-3" /> Users
      </h1>
      <div className="d-flex justify-content-center align-items-center">
        <div className="w-100 bg-light">
        {props.loading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {
          (props.users.length === 0 && !props.loading) && (
            <p style={{fontSize: 20, textAlign: "center"}}>No active users found!</p>
          )
        }
          {props.users.map((item, i) => (
            <div
              className="user bg-white rounded shadow-sm p-2 my-3 mx-2 "
              key={i}
            >
              <div className="d-flex justify-content-start flex-column flex-sm-row align-items-start align-items-sm-center">
                <div className="d-flex justify-content-start align-items-center border-right mb-2 mb-sm-0 flex-1 border-mobile-0">
                  <img
                    src="/images/logo/avatar.png"
                    alt=""
                    className="img-fluid img-thumbnail rounded-circle img-sqare"
                  />
                  <div className="h5 font-weight-bold mx-3">{item.name}</div>
                </div>
                <div className="d-flex justify-content-center flex-row align-items-center border-right border-mobile-0 flex-1 mb-2 mb-sm-0 ">
                  <a
                    className="h6 font-weight-normal mx-3"
                    href={`mailto:${item.email}`}
                  >
                    {item.email}
                  </a>
                </div>
                <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
                  <div className="h6 font-weight-normal mx-3">
                  {item.business_name}
                  </div>
                </div>
                <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
                <Link to={{ pathname: (item.website?.indexOf('://') === -1) ? 'http://' + item.website : item.website }} target="_blank">
                  {/* <a
                    className="h6 font-weight-normal mx-3"
                    target="_blank"
                    href={item.website}
                    rel={'external'}
                  > */}
                    {item.website}
                  {/* </a> */}
                  </Link>
                </div>
                <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
                  <div className="h6 font-weight-normal mx-3">{item.created_on}</div>
                </div>
                <div className="d-flex justify-content-center flex-row align-items-center flex-1  mb-2 mb-sm-0 border-mobile-0 ml-3 ml-sm-0 ">
                  <button className="btn btn-danger" onClick={() => handleChangeStatus(item.id, "rejected")}>Block</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {(props.users.length > 0) && (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Pagination
            activePage={state.activePage}
            itemsCountPerPage={state.limit}
            totalItemsCount={props.userCount}
            pageRangeDisplayed={5}
            onChange={(count) => handlePageChange(count)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  loading: state.users.loading,
  users: state.users.users,
  userCount: state.users.userCount,
});
export default connect(mapStateToProps, {
  fetchUsers,
  changeStatus,
})(UsersAdmin);
