import {
  RESOURCES_LOADING,
  RESOURCES_SET_DATA,
  RESOURCES_ERROR,
} from "./constants";
import { _fetchResources, _editResource, _deleteResource, _createResource } from "./services";
import { errorParser } from '../../services/apiErrorParser';

/* resources actions */
export function resourcesSetLoading(loading) {
  return {
    type: RESOURCES_LOADING,
    payload: loading,
  };
}

export function resourcesSetData(data) {
  return {
    type: RESOURCES_SET_DATA,
    payload: data,
  };
}

export function resourcesSetError(error) {
  return {
    type: RESOURCES_ERROR,
    payload: error,
  };
}

export const fetchResources = (params) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(resourcesSetLoading(true));
		_fetchResources(params).then(async (res) => {
			await dispatch(resourcesSetData({type: params.type, value: res.feeds}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(resourcesSetError(parsedError));
			reject(err)
		}).finally(() => {
			dispatch(resourcesSetLoading(false));
		})
	})
};

export const editResource = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_editResource(formData).then(async (res) => {
			await dispatch(fetchResources({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(resourcesSetError(parsedError));
			reject(err)
		})
	})
};

export const deleteResource = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_deleteResource(formData).then(async (res) => {
			await dispatch(fetchResources({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(resourcesSetError(parsedError));
			reject(err)
		})
	})
};

export const createResource = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_createResource(formData).then(async (res) => {
			await dispatch(fetchResources({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(resourcesSetError(parsedError));
			reject(err)
		})
	})
};