import axios from "axios";
import { BASE_URL } from "../../config";
import { getToken } from "../../utils/authManager";
export function _fetchPlans() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}AdsSubscription/fetchPlans`,{
        headers:{
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createSubscription(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}AdsSubscription/create`,formData,{
        headers:{
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _changeStatus(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}AdsSubscription/changeStatus`,formData,{
        headers:{
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}