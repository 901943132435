import React, { useEffect } from "react";
import "./Payments.css";
import { FiCreditCard } from "react-icons/fi";
import { connect } from "react-redux";
import { fetchSubscribedUsers } from "../../store/users/actions";
import { changeStatus } from "../../store/subscriptions/actions";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const Payments = (props) => {
  useEffect(() => {
    props.fetchSubscribedUsers();
  }, []);
  const handleChangeStatus = async (id, status) => {
    await props.changeStatus({
      id: parseInt(id),
      status,
    });
    if (status === 1) {
      Swal.fire("Subscription approved successfully", "", "success");
    } else {
      Swal.fire(
        "Subscription Deactivated",
        "You can again approve this subscription",
        "success"
      );
    }
  };
  return (
    <div className="admin-Payments">
      <h1 className="py-2 border-bottom d-flex justify-content-start align-items-center mb-3 text-header-sm">
        <FiCreditCard className="mr-3" /> Payments
      </h1>
      <ul
        className="nav nav-pills justify-content-center mb-3 "
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item">
          <a
            className="nav-link active text-sm"
            id="pills-home-tab"
            data-toggle="pill"
            href="#pills-Need-Verification"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Need Verification
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link text-sm"
            id="pills-profile-tab"
            data-toggle="pill"
            href="#pills-Verified"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            Verified
          </a>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-Need-Verification"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div className="d-flex justify-content-center align-items-center">
            <div className="w-100 bg-light">
              {props.subscribedUsers
                .filter((user) => user.subscription_status == 0)
                .map((item, i) => (
                  <div
                    className="user bg-white rounded shadow-sm p-2 my-3 mx-2 "
                    key={i}
                  >
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-start align-items-sm-center">
                      <div className="d-flex justify-content-start align-items-center border-right  flex-1 mb-2 mb-sm-0 border-mobile-0 ">
                        <img
                          src="/images/logo/avatar.png"
                          alt=""
                          className="img-fluid img-thumbnail rounded-circle img-sqare"
                        />
                        <div className="h5 font-weight-bold mx-3">
                          {item.name}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1  mb-2 mb-sm-0 border-mobile-0 ">
                        <a
                          className="h6 font-weight-normal mx-3"
                          href={`mailto:${item.email}`}
                        >
                          {item.email}
                        </a>
                      </div>
                      <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1  mb-2 mb-sm-0 border-mobile-0 ">
                        <div className="h6 font-weight-normal mx-3">
                          {item.business_name}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1  mb-2 mb-sm-0 border-mobile-0 ">
                        <Link
                          to={{
                            pathname:
                              item.website?.indexOf("://") === -1
                                ? "http://" + item.website
                                : item.website,
                          }}
                          target="_blank"
                        >
                          {item.website}
                        </Link>
                      </div>
                      <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1  mb-2 mb-sm-0 border-mobile-0 ">
                        <div className="h6 font-weight-bold font-italic mx-3">
                          {item.subscription_name} (${item.price})
                        </div>
                      </div>
                      <div className="d-flex justify-content-center flex-row align-items-center flex-1  mb-2 mb-sm-0 border-mobile-0 ">
                        <button
                          className="btn btn-outline-success mr-1 ml-3 ml-sm-0"
                          onClick={() => handleChangeStatus(item.id, 1)}
                        >
                          Activate
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-Verified"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <div className="d-flex justify-content-center align-items-center">
            <div className="w-100 bg-light">
              {props.subscribedUsers
                .filter((user) => user.subscription_status == 1)
                .map((item, i) => (
                  <div
                    className="user bg-white rounded shadow-sm p-2 my-3 mx-2 "
                    key={i}
                  >
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-start align-items-sm-center">
                      <div className="d-flex justify-content-start align-items-center border-right flex-1mb-2 mb-sm-0 border-mobile-0">
                        <img
                          src="/images/logo/avatar.png"
                          alt=""
                          className="img-fluid img-thumbnail rounded-circle img-sqare"
                        />
                        <div className="h5 font-weight-bold mx-3">
                          {item.name}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0">
                        <a
                          className="h6 font-weight-normal mx-3"
                          href={`mailto:${item.email}`}
                        >
                          {item.email}
                        </a>
                      </div>
                      <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0">
                        <div className="h6 font-weight-normal mx-3">
                          {item.business_name}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1  mb-2 mb-sm-0 border-mobile-0 ">
                        <Link
                          to={{
                            pathname:
                              item.website?.indexOf("://") === -1
                                ? "http://" + item.website
                                : item.website,
                          }}
                          target="_blank"
                        >
                          {item.website}
                        </Link>
                      </div>
                      <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0">
                        <div className="h6 font-weight-bold font-italic mx-3">
                          {item.subscription_name} (${item.price})
                        </div>
                      </div>
                      {/* <div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0">
                      <div className="h6 font-weight-bold font-italic mx-3">
                        28 days / 30 days left
                      </div>
                    </div> */}
                      <div className="d-flex justify-content-center flex-row align-items-center flex-1 mb-2 mb-sm-0 border-mobile-0">
                        <button
                          className="btn btn-outline-danger mr-1 ml-3 ml-sm-0"
                          onClick={() => handleChangeStatus(item.id, 0)}
                        >
                          Deactivate
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.users.loading,
  subscribedUsers: state.users.subscribedUsers,
  subscribedUserCount: state.users.subscribedUserCount,
});
export default connect(mapStateToProps, {
  fetchSubscribedUsers,
  changeStatus,
})(Payments);
