import React, { Component } from "react";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import { FiUsers, FiUserCheck, FiCreditCard, FiAperture, FiRss } from "react-icons/fi";

const SideBar = (props) => {
  return (
    <div className={`sidebar bg-transparent border-right ${props.open} `}>
      <NavLink className="sidebar_Nav text-decoration-none" to="/admin/users">
        <FiUsers className="icon" /> <span> Users </span>
      </NavLink>
      <NavLink
        className="sidebar_Nav text-decoration-none"
        to="/admin/new-users"
      >
        <FiUserCheck className="icon" /> <span> New Users</span>
      </NavLink>
      <NavLink
        className="sidebar_Nav text-decoration-none"
        to="/admin/payments"
      >
        <FiCreditCard className="icon" /> <span> Payments </span>
      </NavLink>
      <NavLink className="sidebar_Nav text-decoration-none" to="/admin/posts">
        <FiAperture className="icon" /> <span> Posts </span>
      </NavLink>
      <NavLink className="sidebar_Nav text-decoration-none" to="/admin/resources">
        <FiRss className="icon" /> <span> Resources </span>
      </NavLink>
    </div>
  );
};

export default SideBar;
