import {
  MEDIA_LOADING,
  MEDIA_SET_DATA,
  MEDIA_ERROR,
  MEDIA_SET_PENDING_MEDIA,
  MEDIA_SET_APPROVED_MEDIA,
} from "./constants";
import { _createMedia, _fetchOwnAd, _fetchAllAds,_changeStatus } from "./services";
import { errorParser } from "../../services/apiErrorParser";

/* Media actions */
export function mediaSetLoading(loading) {
  return {
    type: MEDIA_LOADING,
    payload: loading,
  };
}
export function mediaSetData(data) {
  return {
    type: MEDIA_SET_DATA,
    payload: data,
  };
}
export function mediaSetError(error) {
  return {
    type: MEDIA_ERROR,
    payload: error,
  };
}
export function mediaSetPendingData(data) {
  return {
    type: MEDIA_SET_PENDING_MEDIA,
    payload: data,
  };
}
export function mediaSetApprovedData(data) {
  return {
    type: MEDIA_SET_APPROVED_MEDIA,
    payload: data,
  };
}
export const createMedia = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(mediaSetLoading(true));
    _createMedia(params)
      .then(async (res) => {
        // await dispatch(usersSetData(res));
        resolve(res);
      })
      .catch((err) => {
        const parsedError = errorParser.parseError(err);
        dispatch(mediaSetError(parsedError));
        reject(err);
      })
      .finally(() => {
        dispatch(mediaSetLoading(false));
      });
  });
};
export const fetchOwnAd = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(mediaSetLoading(true));
    _fetchOwnAd()
      .then(async (res) => {
        await dispatch(mediaSetData(res.user_ad));
        resolve(res);
      })
      .catch((err) => {
        const parsedError = errorParser.parseError(err);
        dispatch(mediaSetError(parsedError));
        reject(err);
      })
      .finally(() => {
        dispatch(mediaSetLoading(false));
      });
  });
};
export const fetchAllAds = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(mediaSetLoading(true));
    _fetchAllAds(params)
      .then(async (res) => {
		if(params.status === 0){
			await dispatch(mediaSetPendingData(res));
		}else{
			await dispatch(mediaSetApprovedData(res));
		}
        resolve(res);
      })
      .catch((err) => {
        const parsedError = errorParser.parseError(err);
        dispatch(mediaSetError(parsedError));
        reject(err);
      })
      .finally(() => {
        dispatch(mediaSetLoading(false));
      });
  });
};
export const changeStatus = (params) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(mediaSetLoading(true));
		_changeStatus(params).then(async (res) => {
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(mediaSetError(parsedError));
			reject(err)
		}).finally(() => {
			dispatch(mediaSetLoading(false));
		})
	})
};