import React from "react";
import HeaderAdmin from "../../components/template/headerAdmin/HeaderAdmin";
import SideBar from "../../components/template/sideBar/SideBar";

const AdminView = (props) => {
  const { children } = props;
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <main>
      <HeaderAdmin toggleSidebar={() => {setSidebarOpen(!sidebarOpen)}} />
      <div className="d-flex justify-content-start align-items-start align-items-center margin_top_adjust">
        <SideBar open={sidebarOpen} />
        <div className="px-3 py-3 main-body flex-1">{children}</div>
      </div>
    </main>
  );
};

export default AdminView;
