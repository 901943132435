import {
    SUBSCRIPTIONS_LOADING,
    SUBSCRIPTIONS_SET_DATA,
    SUBSCRIPTIONS_ERROR,
    SUBSCRIPTIONS_SET_PLANS
  } from "./constants";

  const initialState= {
    loading: false,
    errorMessage: {},
    plans: [],
    userCount: 0
  };
  
  export function subscriptionReducer(
    state = initialState,
    action
  ){
    switch (action.type) {
      case SUBSCRIPTIONS_LOADING:
          return {
            ...state,
            loading: action.payload
          };
      case SUBSCRIPTIONS_ERROR:
          return {
            ...state,
            errorMessage: action.payload
          };
      case SUBSCRIPTIONS_SET_PLANS:
          return {
            ...state,
            plans: action.payload
          };
      case SUBSCRIPTIONS_SET_DATA:
          return {
            ...state,
            users: action.payload.users,
            userCount: parseInt(action.payload.total),
          };
      default:
        return state;
    }
  }
  