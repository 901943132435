import React, { useState, useEffect } from "react";
import "./Resources.css";
import { FiRss } from "react-icons/fi";
import { connect } from "react-redux";
import {Link} from "react-router-dom";

const Resources = (props) => {
  const data = [{
    title: "News",
    icon: "fa-newspaper-o",
    desc: "Add/Edit your news RSS feeds",
    link: props.profile?.profile?.role === "admin" ? "/admin/resources/news" : "/resources/news"
  }, {
    title: "Podcasts",
    icon: "fa-podcast",
    desc: "Add/Edit your podcasts RSS feeds",
    link: props.profile?.profile?.role === "admin" ? "/admin/resources/podcasts" : "/resources/podcasts"
  }, {
    title: "Webinars",
    icon: "fa-users",
    desc: "Add/Edit your webinars RSS feeds",
    link: props.profile?.profile?.role === "admin" ? "/admin/resources/webinars" : "/resources/webinars"
  }, {
    title: "Study",
    icon: "fa-book",
    desc: "Add/Edit your study feeds",
    link: props.profile?.profile?.role === "admin" ? "/admin/resources/study" : "/resources/study"
  }, {
    title: "Products",
    icon: "fa-shopping-cart",
    desc: "Add/Edit your products",
    link: props.profile?.profile?.role === "admin" ? "/admin/resources/products" : "/resources/products"
  }]
  console.log(props.profile);
  return (
    <div className="admin-users mt-4">
      <h1 className="py-2 border-bottom d-flex justify-content-start align-items-center text-header-sm">
        <FiRss className="mr-3" /> Resources
      </h1>
      <div className="d-flex justify-content-center align-items-center">
        <div className="w-100 bg-light">
          <div className="container-fluid" style={{height: "100vh"}}>
            <div className="row">
              {data.map((d, i) => (
                <Link className="col-sm-3 price-table mb-3" key={i} style={{textDecoration: "none"}} to={d.link}>
                  <div className="card text-center">
                    <div className="title">
                      <i
                        className={`fa ${d.icon}`}
                      ></i>
                      <h2>{d.title}</h2>
                    </div>
                    <div className="option">
                      <ul>
                        <li>{d.desc}</li>
                      </ul>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  profile: state.profile.profile
});
export default connect(mapStateToProps, {
})(Resources);
