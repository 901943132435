import React, { useEffect } from "react";
import "./Pricing.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchPlans,
  createSubscription,
} from "../../store/subscriptions/actions";

const Pricing = (props) => {
  useEffect(() => {
    props.fetchPlans();
  }, []);
  const handleSubscribe = (id) => {
    props.createSubscription({
      subscription_id: id,
    });
  };
  return (
    <div className="pricing">
      <section className="price-sec">
        <div className="container mb-5 mt-n5">
          <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h1 className="display-4">Choose Pricing</h1>
            <p className="lead">
              Choose a suitable pricing plan for Tune In Psychedelics ad portal
            </p>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container">
            <div className="row">
              {props.plans.map((plan, i) => (
                <div className="col-sm-4 price-table" key={i}>
                  <div className="card text-center">
                    <div className="title">
                      <i
                        className={
                          i === 0
                            ? "fa fa-paper-plane"
                            : i === 1
                            ? "fa fa-plane"
                            : "fa fa-rocket"
                        }
                      ></i>
                      <h2>{plan.name}</h2>
                    </div>
                    <div className="price">
                      <h4>
                        <sup>$</sup>
                        {plan.price}
                      </h4>
                    </div>
                    <div className="option">
                      <ul>
                        <li>{plan.description}</li>
                      </ul>
                    </div>
                    {props.profile.profile && (
                      <>
                        {props.profile.subscription ? (
                          props.profile.subscription.subscription_id ==
                          plan.id ? (
                            <>
                              {props.profile.subscription.status == 1 ? (
                                <strong className="text-warning">
                                  Subscribed to this plan!
                                </strong>
                              ) : (
                                <strong className="text-warning">
                                  Waiting for admin approval!
                                </strong>
                              )}
                            </>
                          ) : (
                            <button onClick={() => handleSubscribe(plan.id)}>
                              Subscribe
                            </button>
                          )
                        ) : (
                          <button onClick={() => handleSubscribe(plan.id)}>
                            Subscribe
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
const mapStateToProps = (state) => ({
  loading: state.subscriptions.loading,
  plans: state.subscriptions.plans,
  profile: state.profile.profile,
});
export default connect(mapStateToProps, {
  fetchPlans,
  createSubscription,
})(Pricing);
