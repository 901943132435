import {
  WEBINARS_LOADING,
  WEBINARS_SET_DATA,
  WEBINARS_ERROR,
} from "./constants";

const initialState = {
  loading: false,
  errorMessage: {},
  webinars: []
};

export function webinarReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case WEBINARS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case WEBINARS_ERROR:
      return {
        ...state,
        errorMessage: action.payload
      };
    case WEBINARS_SET_DATA:
      return {
        ...state,
        webinars: action.payload
      };
    default:
      return state;
  }
}
