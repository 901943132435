const TOKEN_KEY = 'TUNEIN_TOKEN';
const ROLE_KEY = 'TUNEIN_ROLE';

export const setToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
}

export const getToken = () => {
    return localStorage.getItem(TOKEN_KEY);
}

export const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const setRole = (role) => {
    localStorage.setItem(ROLE_KEY, role);
}

export const getRole = () => {
    return localStorage.getItem(ROLE_KEY);
}

export const removeRole = () => {
    localStorage.removeItem(ROLE_KEY);
}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }

    return false;
}