import {
  SUBSCRIPTIONS_LOADING,
  SUBSCRIPTIONS_SET_DATA,
  SUBSCRIPTIONS_SET_PLANS,
  SUBSCRIPTIONS_ERROR,
} from "./constants";
import { _fetchPlans, _createSubscription, _changeStatus } from "./services";
import { errorParser } from '../../services/apiErrorParser';
import {fetchProfile} from "../profile/actions";
import {fetchSubscribedUsers} from "../users/actions";

/* Subscriptions actions */
export function subscriptionsSetLoading(loading) {
  return {
    type: SUBSCRIPTIONS_LOADING,
    payload: loading,
  };
}

export function subscriptionsSetData(data) {
  return {
    type: SUBSCRIPTIONS_SET_DATA,
    payload: data,
  };
}

export function subscriptionsSetPlans(data) {
	return {
	  type: SUBSCRIPTIONS_SET_PLANS,
	  payload: data,
	};
  }

export function subscriptionsSetError(error) {
  return {
    type: SUBSCRIPTIONS_ERROR,
    payload: error,
  };
}

export const fetchPlans = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(subscriptionsSetLoading(true));
		_fetchPlans().then(async (res) => {
			await dispatch(subscriptionsSetPlans(res.plans));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(subscriptionsSetError(parsedError));
			reject(err)
		}).finally(() => {
			dispatch(subscriptionsSetLoading(false));
		})
	})
};
export const createSubscription = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_createSubscription(formData).then(async (res) => {
			await dispatch(fetchProfile());
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(subscriptionsSetError(parsedError));
			reject(err)
		})
	})
};
export const changeStatus = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_changeStatus(formData).then(async (res) => {
			await dispatch(fetchSubscribedUsers());
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(subscriptionsSetError(parsedError));
			reject(err)
		})
	})
};