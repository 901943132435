import axios from "axios";
import { BASE_URL } from "../../config";
import { getToken } from "../../utils/authManager";
export function _fetchUsers(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}AdsUser/show?status=${params.status}&limit=${params.limit}&offset=${params.offset}`,{
        headers:{
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _changeStatus(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}AdsUser/changeStatus`,formData,{
        headers:{
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchSubscribedUsers(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}AdsSubscription/fetchSubscribedUsers`,{
        headers:{
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}