import React from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { removeToken, removeRole } from "../../../utils/authManager";
import { connect } from "react-redux";
import { fetchProfile } from "../../../store/profile/actions";

function Header(props) {
  var history = useHistory();
  const [open, setOpen] = React.useState(false);
  const handleLogout = async () => {
    await removeToken();
    await removeRole();
    history.push("/");
  };
  return (
    <div className="d-flex flex-row flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow fixed-top justify-content-between ">
      <Link to="/admin/users" className="my-0 mr-md-auto text-decoration-none">
        <h5 className="my-0 mr-md-auto font-weight-bold text-white color-violet text-header-sm">
          <img src="/images/logo/logo.png" alt="" className="logo" />
          <span className="d-none d-sm-inline">Tune In Psychedelics</span>
        </h5>
      </Link>
      <div className="d-none d-sm-flex">
        <nav className="my-2 my-md-0 mr-md-3">
          <NavLink className="p-2 text-dark link-nav" to="/dashboard">
            Dashboard
          </NavLink>
          <NavLink className="p-2 text-dark link-nav" to="/pricing">
            Pricing
          </NavLink>
          <NavLink className="p-2 text-dark link-nav" to="/resources">
            Resources
          </NavLink>
          <span className="btn btn-link text-danger" onClick={() => handleLogout()}>
            Log out
          </span>
        </nav>

      </div>
      <div className="d-flex d-sm-none">
        <FiMenu
          className="menu border-right border-mobile-0 p-2 m-0 "
          style={{ zIndex: 1000 }}
          onClick={() => {
            setOpen(!open);
          }}
        />
      </div>
      <div className={`mobile-menu ${open} d-none`}>
        <NavLink
          className="p-2 text-dark link-nav mobile-nav"
          to="/dashboard"
          onClick={() => {
            setOpen(!open);
          }}
        >
          Dashboard
        </NavLink>
        <NavLink
          className="p-2 text-dark link-nav mobile-nav"
          to="/pricing"
          onClick={() => {
            setOpen(!open);
          }}
        >
          Pricing
        </NavLink>
        <NavLink className="p-2 text-dark mobile-nav" to="/resources">
          Resources
        </NavLink>
        <NavLink
          className="btn btn-gradient mt-5 btn-small"
          to="/"
          onClick={() => {
            setOpen(!open);
          }}
        >
          Log out
        </NavLink>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  loading: state.profile.loading,
  profile: state.profile.profile
});
export default connect(mapStateToProps, {
  fetchProfile,
})(Header);
