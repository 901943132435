import {
    USERS_LOADING,
    USERS_SET_DATA,
    USERS_ERROR,
    USERS_SET_SUBSCRIBED_USERS
  } from "./constants";

  const initialState= {
    loading: false,
    errorMessage: {},
    users: [],
    userCount: 0,
    subscribedUsers: [],
    subscribedUserCount: 0,
  };
  
  export function userReducer(
    state = initialState,
    action
  ){
    switch (action.type) {
      case USERS_LOADING:
          return {
            ...state,
            loading: action.payload
          };
      case USERS_ERROR:
          return {
            ...state,
            errorMessage: action.payload
          };
      case USERS_SET_DATA:
          return {
            ...state,
            users: action.payload.users,
            userCount: parseInt(action.payload.total),
          };
      case USERS_SET_SUBSCRIBED_USERS:
        return {
          ...state,
          subscribedUsers: action.payload.users,
          subscribedUserCount: parseInt(action.payload.total),
        };
      default:
        return state;
    }
  }
  