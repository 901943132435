import axios from "axios";
import { BASE_URL } from "../../config";
import { getToken } from "../../utils/authManager";
export function _fetchWebinars(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}Webinars/fetchAll`, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _editWebinar(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}Webinars/updateWebinar`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _deleteWebinar(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}Webinars/deleteWebinar`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createWebinar(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}Webinars/create`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}