import {
  WEBINARS_LOADING,
  WEBINARS_SET_DATA,
  WEBINARS_ERROR,
} from "./constants";
import { _fetchWebinars, _editWebinar, _deleteWebinar, _createWebinar } from "./services";
import { errorParser } from '../../services/apiErrorParser';

/* webinars actions */
export function webinarSetLoading(loading) {
  return {
    type: WEBINARS_LOADING,
    payload: loading,
  };
}

export function webinarSetData(data) {
  return {
    type: WEBINARS_SET_DATA,
    payload: data,
  };
}

export function webinarSetError(error) {
  return {
    type: WEBINARS_ERROR,
    payload: error,
  };
}

export const fetchWebinars = (params) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(webinarSetLoading(true));
		_fetchWebinars(params).then(async (res) => {
			await dispatch(webinarSetData(res.webinars));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(webinarSetError(parsedError));
			reject(err)
		}).finally(() => {
			dispatch(webinarSetLoading(false));
		})
	})
};

export const editWebinar = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_editWebinar(formData).then(async (res) => {
			await dispatch(fetchWebinars({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(webinarSetError(parsedError));
			reject(err)
		})
	})
};

export const deleteWebinar = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_deleteWebinar(formData).then(async (res) => {
			await dispatch(fetchWebinars({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(webinarSetError(parsedError));
			reject(err)
		})
	})
};

export const createWebinar = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_createWebinar(formData).then(async (res) => {
			await dispatch(fetchWebinars({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(webinarSetError(parsedError));
			reject(err)
		})
	})
};