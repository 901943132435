import React, { useEffect, useState } from "react";
import "./MediaUpload.css";
import styled from "styled-components";
import { connect } from "react-redux";
import { createMedia } from "../../store/media/actions";
import Swal from "sweetalert2";
import { fetchOwnAd } from "../../store/media/actions";
import {UPLOAD_URL} from "../../config/index";

const MediaUpload = (props) => {
  const [state, setState] = useState({
    title: "",
    link: "",
    portraitImage: "",
    landscapeImage: "",
    btnLoading: false,
  });
  const [portraitImage, setPortraitImage] = useState(null);
  const [landscapeImage, setLandscapeImage] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setState({ ...state, btnLoading: true });
      let formData = new FormData();
      formData.append("title", state.title);
      formData.append("link", state.link);
      formData.append("portrait", portraitImage);
      formData.append("landscape", landscapeImage);
      await props.createMedia(formData);
      Swal.fire("Media uploaded successfully", "It will be verified by admin before publishing", "success").then(()=>{
        props.history.push("/dashboard")
      })
    } catch (err) {
      Swal.fire("Something went wrong", "", "error")
    } finally {
      setState({ ...state, btnLoading: false });
    }
  };
  useEffect(()=>{
    if(props.match?.params?.id){
      props.fetchOwnAd()
    }
  }, [props.match.params])
  useEffect(()=>{
    if(props.media.id){
      setState({
        ...state, 
        title: props.media.title,
        link: props.media.link,
        portraitImage: UPLOAD_URL + props.media.portrait_media,
        landscapeImage: UPLOAD_URL + props.media.landscape_media
      })
    }
  }, [props.media])
  return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <div className="form">
          {/* <img src="/images/logo/logo.png" alt="" className="logo_navabr" /> */}
          <h3 className="login-header">Upload Media</h3>
          <form onSubmit={handleSubmit}>
            <div className="input_wrapper">
              <div className="label_wrapper">
                <label htmlFor="email" className="label">
                  Title
                </label>
              </div>
              <div className="inputbox_wrapper">
                <input
                  type="text"
                  id="email"
                  className="input"
                  required
                  value={state.title}
                  onChange={(e) =>
                    setState({ ...state, title: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="input_wrapper">
              <div className="label_wrapper">
                <label htmlFor="email" className="label">
                  Link
                </label>
              </div>
              <div className="inputbox_wrapper">
                <input
                  type="text"
                  id="email"
                  className="input"
                  required
                  value={state.link}
                  onChange={(e) => setState({ ...state, link: e.target.value })}
                />
              </div>
            </div>
            <div className="input_wrapper">
              <div className="label_wrapper">
                <label className="label">Media</label>
              </div>
              <div className="inputbox_wrapper">
                <label htmlFor="up" className="upload">
                  <span>UPLOAD PORTRAIT IMAGE</span>
                </label>
                <input
                  type="file"
                  id="up"
                  className="input"
                  style={{ opacity: 0, visibility: "hidden" }}
                  onChange={(e) => setPortraitImage(e.target.files[0])}
                  accept=".jpg,.jpeg,.png,.gif"
                />
                {state.portraitImage && (
                <img
                  src={state.portraitImage}
                  alt=""
                  className="upload-img"
                />
                )}
              </div>
              <div className="inputbox_wrapper">
                <label htmlFor="landscape" className="upload">
                  <span>UPLOAD LANDSCAPE IMAGE</span>
                </label>
                <input
                  type="file"
                  id="landscape"
                  className="input"
                  style={{ opacity: 0, visibility: "hidden" }}
                  onChange={(e) => setLandscapeImage(e.target.files[0])}
                  accept=".jpg,.jpeg,.png,.gif"
                />
                {state.landscapeImage && (
                <img
                  src={state.landscapeImage}
                  alt=""
                  className="upload-img"
                />
                )}
              </div>
            </div>
            <button className="Login_button" type="submit" disabled={state.btnLoading}>
              {state.btnLoading ? "..." : props.match?.params?.id ? "Edit Details" : "Save & Continue"}
            </button>
          </form>
        </div>
      </div>
  );
};
const mapStateToProps = (state) => ({
  loading: state.media.loading,
  media: state.media.media,
});
export default connect(mapStateToProps, {
  createMedia,
  fetchOwnAd
})(MediaUpload);
