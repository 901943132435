import React, { useState } from "react";
import "./Login.css";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { connect } from "react-redux";
import { checkLogin } from "../../../store/auth/actions";

const LoginWrapepr = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: linear-gradient(60.4deg, #eba1e2, rgb(255, 25, 247), #9867fd) 0%
    0% / 100% 100%;
  box-sizing: border-box;
  flex: 1 1 0%;
  flexdirection: row;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

const Login = (props) => {
  const [isPassword, setIsPassword] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    btnLoading: false,
  });
  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setState({...state, btnLoading: true});
      const { checkLogin } = props;
      await checkLogin({ email: state.email, password: state.password });
      props.history.push("/dashboard");
    } catch (err) {
      setErrors(err.response.data)
    } finally {
      setState({...state, btnLoading: false});
    }
  };
  return (
    <LoginWrapepr className="login2">
      <div className="left-Login">
        <div className="form">
          <img src="/images/logo/logo.png" alt="" className="logo_navabr" />
          <h3 className="login-header">
            Log In to <br /> Tune In Psychedelics
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="input_wrapper">
              <div className="label_wrapper">
                <label htmlFor="email" className="label">
                  Email Address
                </label>
              </div>
              <div className="inputbox_wrapper">
                <input
                  type="email"
                  id="email"
                  className="input"
                  value={state.email}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="input_wrapper">
              <div className="label_wrapper">
                <label htmlFor="password" className="label">
                  Password
                </label>
                {/* <label htmlFor="" className="label_forgot_pass_wrapper">
                  <Link to="" className="label_forgot_pass">
                    Forgot your Password?
                  </Link>
                </label> */}
              </div>
              <div className="inputbox_wrapper">
                <input
                  type={isPassword === false ? `password` : `text`}
                  id="password"
                  className="input"
                  value={state.password}
                  onChange={(e) =>
                    setState({ ...state, password: e.target.value })
                  }
                  required
                />
                <div
                  className="password_toggler"
                  onClick={() => {
                    setIsPassword(!isPassword);
                  }}
                >
                  <span className="password_toggler_icon_wrapper">
                    {isPassword ? (
                      <FaRegEye className="password_toggler_icon" />
                    ) : (
                      <FaRegEyeSlash className="password_toggler_icon" />
                    )}
                  </span>
                </div>
                {errors.message && <p className="error-msg">{errors.message}</p>}
              </div>
            </div>
            <button
              className="Login_button"
              type="submit"
              disabled={state.btnLoading}
            >
              {state.btnLoading ? "..." : "Log In"}
            </button>
            <div className="or_login_wrapper">
              <div className="or_login_line"></div>
              <div className="or_login_text">OR</div>
              <div className="or_login_line"></div>
            </div>
            <Link to="/signup">
              <button className="log_with_other">Sign Up</button>
            </Link>
          </form>
        </div>
      </div>
      <div className="right-Login">
        <img src="/images/logo/logo.png" className="logo_right" />
      </div>
    </LoginWrapepr>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});
export default connect(mapStateToProps, {
  checkLogin,
})(Login);
