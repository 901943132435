import React, { useState } from "react";
import { connect } from "react-redux";
import { createStudy } from "../../../store/studies/actions";
import $ from "jquery";

const CreateForm = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [link, setLink] = useState("");
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const handleCreateStudyFeed = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            await props.createStudy({ name, link, desc });
            $('#studyCreateModal').modal('hide');
            setLink("");
        } catch (err) {

        } finally {
            setLoading(false);
        }
    }
    return (
        <div className="modal fade" id="studyCreateModal" tabindex="-1" role="dialog" aria-labelledby="studyCreateModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form action="" onSubmit={handleCreateStudyFeed}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="studyCreateModalLabel">Add study feed</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <input type="text" className="form-control mb-3" placeholder="Enter study name" required value={name} onChange={(e) => setName(e.target.value)} />
                            <input type="text" className="form-control mb-3" placeholder="Enter URL" required value={link} onChange={(e) => setLink(e.target.value)} />
                            <textarea className="form-control" placeholder="Enter description" required rows="10" value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" type="submit" disabled={isLoading}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
    createStudy
})(CreateForm);