import axios from "axios";
import { BASE_URL } from "../../config";
import { getToken } from "../../utils/authManager";
export function _createMedia(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}AdsMedia/create`,formData,{
        headers:{
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchOwnAd(formData) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}AdsMedia/fetchOwnAd`,{
        headers:{
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _fetchAllAds(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}AdsMedia/fetchAllAds?status=${params.status}&limit=${params.limit}&offset=${params.offset}`,{
        headers:{
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _changeStatus(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}AdsMedia/changeStatus`,formData,{
        headers:{
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}