import {
  MEDIA_LOADING,
  MEDIA_SET_DATA,
  MEDIA_ERROR,
  MEDIA_SET_PENDING_MEDIA,
  MEDIA_SET_APPROVED_MEDIA,
} from "./constants";

const initialState = {
  loading: false,
  errorMessage: {},
  media: {},
  pendingMedia: [],
  pendingMediaCount: 0,
  approvedMedia: [],
  approvedMediaCount: 0,
};

export function mediaReducer(state = initialState, action) {
  switch (action.type) {
    case MEDIA_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case MEDIA_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case MEDIA_SET_DATA:
      return {
        ...state,
        media: action.payload.length > 0 ? action.payload[0] : {},
      };
    case MEDIA_SET_PENDING_MEDIA:
      return {
        ...state,
        pendingMedia: action.payload.ads,
        pendingMediaCount: parseInt(action.payload.total),
      };
    case MEDIA_SET_APPROVED_MEDIA:
      return {
        ...state,
        approvedMedia: action.payload.ads,
        approvedMediaCount: parseInt(action.payload.total),
      };
    default:
      return state;
  }
}
