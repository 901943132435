import React, { useState } from "react";
import { connect } from "react-redux";
import { editProduct, deleteProduct } from "../../../store/products/actions";
import Swal from "sweetalert2";
import { UPLOAD_URL } from "../../../config/index";

const TableRow = ({ item, editProduct, deleteProduct }) => {
	const [isEditable, setEditable] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [link, setLink] = useState(item.link);
	const [name, setName] = useState(item.name);
	const [desc, setDesc] = useState(item.description);
	const [image, setImage] = useState(null);

	const handleSave = async (id) => {
		try {
			setLoading(true);
			let formData = new FormData();
            formData.append("link", link);
            formData.append("name", name);
            formData.append("desc", desc);
            formData.append("id", id);
			if(image){
				formData.append("image", image);
			}
			await editProduct(formData);
			setEditable(false);
		} catch (err) {
			Swal.fire("Something went wrong!", "Please try again later", "error");
		} finally {
			setLoading(false);
		}
	}
	const handleCancel = () => {
		setEditable(false);
		setLink(item.link);
	}
	const handleDelete = async (id) => {
		try {
			Swal.fire({
				title: 'Are you sure want to delete this RSS feed?',
				text: 'All the posts from this feed will be deleted from the application!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'No, keep it'
			}).then(async (result) => {
				if (result.isConfirmed) {
					setLoading(true);
					await deleteProduct({ id, type: "products" });
				}
			})
		} catch (err) {
			Swal.fire("Something went wrong!", "Please try again later", "error");
		} finally {
			setLoading(false);
		}
	}
	return (
		<div
			className="user bg-white rounded shadow-sm p-2 my-3 mx-2 "
		>
			<div className="d-flex justify-content-start flex-column flex-sm-row align-items-start align-items-sm-center">
			<div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
					{isEditable ? (
						<input
                                type="file"
                                className="form-control"
                                onChange={(e) => setImage(e.target.files[0])}
                                accept=".jpg,.jpeg,.png,.gif"
                            />
					) : (
						<div className="h6 font-weight-normal mx-3">
							<img src={`${UPLOAD_URL}${item.image}`} alt="" style={{height: 80, width: 80}} />
						</div>
					)}
				</div>
				<div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
					{isEditable ? (
						<input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
					) : (
						<div className="h6 font-weight-normal mx-3">{item.name}</div>
					)}
				</div>
				<div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
					{isEditable ? (
						<textarea className="form-control" onChange={(e) => setDesc(e.target.value)}>{desc}</textarea>
					) : (
						<div className="h6 font-weight-normal mx-3">{item.description.substring(0.100)}...</div>
					)}
				</div>
				<div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
					{isEditable ? (
						<input type="text" value={link} onChange={(e) => setLink(e.target.value)} className="form-control" />
					) : (
						<a href={(item.link?.indexOf('://') === -1) ? 'http://' + item.link : item.link} target="_blank">
							{item.link}
						</a>
					)}
				</div>
				<div className="d-flex justify-content-center flex-row align-items-center border-right flex-1 mb-2 mb-sm-0 border-mobile-0 ">
					<div className="h6 font-weight-normal mx-3">{item.created_on}</div>
				</div>
				<div className="d-flex justify-content-center flex-row align-items-center flex-1">
					{isEditable ? (
						<>
							<button className="btn btn-success" onClick={() => { }} style={{ borderRadius: 0 }} onClick={() => handleSave(item.id)} disabled={isLoading}>Save</button>
							<button className="btn btn-secondary" onClick={() => handleCancel()} style={{ borderRadius: 0 }}>Cancel</button>
						</>
					) : (
						<>
							<button className="btn btn-primary" onClick={() => { }} style={{ borderRadius: 0 }} onClick={() => setEditable(true)} >Edit</button>
							<button className="btn btn-danger" onClick={() => handleDelete(item.id)} style={{ borderRadius: 0 }}>Delete</button>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
	editProduct,
	deleteProduct
})(TableRow);