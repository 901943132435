import React, { useState, useEffect } from "react";
import { FiUsers, FiPlus } from "react-icons/fi";
import TableRow from "./TableRow";
import CreateForm from "./CreateForm";

import { connect } from "react-redux";
import {fetchWebinars} from "../../../store/webinars/actions";

const Webinars = (props) => {
	useEffect(() => {
		props.fetchWebinars()
	}, []);
	return (
		<div className="admin-users" style={{marginTop: props.profile?.profile?.role === "subscriber" ?100: 0, padding: props.profile?.profile?.role === "subscriber" ?"0 30px" : 0}}>
			<div className="d-flex justi-content-between align-items-center w-100">
				<div style={{ flex: 1 }}>
					<h1 className="py-2 border-bottom text-header-sm">
						<FiUsers className="mr-3" /> Webinars
					</h1>
				</div>
				<button className="btn btn-gradient btn-small" data-toggle="modal" data-target="#webinarCreateModal"><FiPlus /> Add Webinars</button>
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div className="w-100 bg-light">
					{props.loading && (
						<div className="d-flex justify-content-center">
							<div className="spinner-border text-info" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}
					{
						(props.webinars.length === 0 && !props.loading) && (
							<p style={{ fontSize: 20, textAlign: "center" }}>No webinars feeds found!</p>
						)
					}
					{props.webinars.map((item, i) => (
						<TableRow key={i} item={item} />
					))}
				</div>
			</div>
			<CreateForm />
		</div>
	);
};
const mapStateToProps = (state) => ({
	loading: state.users.loading,
	webinars: state.webinars.webinars,
	profile: state.profile.profile
});
export default connect(mapStateToProps, {
	fetchWebinars
})(Webinars);
