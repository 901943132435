import React, { useState } from "react";
import { connect } from "react-redux";
import {createResource} from "../../../store/resources/actions";
import $ from "jquery";

const CreateForm = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [link, setLink] = useState("");
    const handleCreateNewsFeed = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            await props.createResource({link, type: "news"});
            $('#newsCreateModal').modal('hide');
            setLink("");
        } catch (err) {

        } finally {
            setLoading(false);
        }
    }
    return (
        <div className="modal fade" id="newsCreateModal" tabindex="-1" role="dialog" aria-labelledby="newsCreateModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form action="" onSubmit={handleCreateNewsFeed}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="newsCreateModalLabel">Add news feed</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <input type="text" className="form-control" placeholder="Enter RSS link" required value={link} onChange={(e) => setLink(e.target.value)} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" type="submit" disabled={isLoading}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
    createResource
})(CreateForm);