import {
  USERS_LOADING,
  USERS_SET_DATA,
  USERS_ERROR,
  USERS_SET_SUBSCRIBED_USERS
} from "./constants";
import { _fetchUsers, _changeStatus, _fetchSubscribedUsers } from "./services";
import { errorParser } from '../../services/apiErrorParser';

/* Users actions */
export function usersSetLoading(loading) {
  return {
    type: USERS_LOADING,
    payload: loading,
  };
}

export function usersSetData(data) {
  return {
    type: USERS_SET_DATA,
    payload: data,
  };
}

export function usersSetSubscribedUsers(data) {
	return {
	  type: USERS_SET_SUBSCRIBED_USERS,
	  payload: data,
	};
  }

export function usersSetError(error) {
  return {
    type: USERS_ERROR,
    payload: error,
  };
}

export const fetchUsers = (params) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(usersSetLoading(true));
		_fetchUsers(params).then(async (res) => {
			await dispatch(usersSetData(res));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(usersSetError(parsedError));
			reject(err)
		}).finally(() => {
			dispatch(usersSetLoading(false));
		})
	})
};
export const changeStatus = (params) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(usersSetLoading(true));
		_changeStatus(params).then(async (res) => {
			await dispatch(fetchUsers({status: "pending", limit: 20, offset: 0}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(usersSetError(parsedError));
			reject(err)
		}).finally(() => {
			dispatch(usersSetLoading(false));
		})
	})
};
export const fetchSubscribedUsers = (params) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(usersSetLoading(true));
		_fetchSubscribedUsers(params).then(async (res) => {
			await dispatch(usersSetSubscribedUsers(res));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(usersSetError(parsedError));
			reject(err)
		}).finally(() => {
			dispatch(usersSetLoading(false));
		})
	})
};