import axios from "axios";
import { BASE_URL } from "../../config";
import { getToken } from "../../utils/authManager";
export function _fetchProducts(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}Products/fetchAll`, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _editProduct(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}Products/updateProduct`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _deleteProduct(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}Products/deleteProduct`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createProduct(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}Products/create`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}