import React from "react";
import Header from '../../components/template/header/Header';

const MainView = (props) => {
  const { children } = props;

  return (
    <main>
      <Header />
      {children}
    </main>
  );
};

export default MainView;
