import axios from "axios";
import { BASE_URL } from "../../config";
import { getToken } from "../../utils/authManager";
export function _fetchStudies(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}Studies/fetchAll`, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _editStudy(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}Studies/updateStudy`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _deleteStudy(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}Studies/deleteStudy`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createStudy(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}Studies/create`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}