
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Reducers imported
import { authReducer } from "./auth/reducers";
import { userReducer } from "./users/reducers";
import { subscriptionReducer } from "./subscriptions/reducers";
import { profileReducer } from "./profile/reducers";
import { mediaReducer } from "./media/reducers";
import { resourceReducer } from "./resources/reducers";
import { studyReducer } from "./studies/reducers";
import { productReducer } from "./products/reducers";
import { webinarReducer } from "./webinars/reducers";

const rootReducer = combineReducers({
  auth: authReducer,
  users: userReducer,
  subscriptions: subscriptionReducer,
  profile: profileReducer,
  media: mediaReducer,
  resources: resourceReducer,
  studies: studyReducer,
  products: productReducer,
  webinars: webinarReducer,
});

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);
  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );
  return store;
}
