import {
    PROFILE_LOADING,
    PROFILE_SET_DATA,
    PROFILE_ERROR
  } from "./constants";

  const initialState= {
    loading: false,
    errorMessage: {},
    profile: {},
  };
  
  export function profileReducer(
    state = initialState,
    action
  ){
    switch (action.type) {
      case PROFILE_LOADING:
          return {
            ...state,
            loading: action.payload
          };
      case PROFILE_ERROR:
          return {
            ...state,
            errorMessage: action.payload
          };
      case PROFILE_SET_DATA:
          return {
            ...state,
            profile: {
              profile: action.payload.profile,
              subscription: action.payload.subscription?.length ? action.payload.subscription[0] : null
            },
          };
      default:
        return state;
    }
  }
  