import {
    RESOURCES_LOADING,
    RESOURCES_SET_DATA,
    RESOURCES_ERROR,
  } from "./constants";

  const initialState= {
    loading: false,
    errorMessage: {},
    news: [],
    podcasts: [],
    webinars: [],
    study: [],
    products: [],
  };
  
  export function resourceReducer(
    state = initialState,
    action
  ){
    switch (action.type) {
      case RESOURCES_LOADING:
          return {
            ...state,
            loading: action.payload
          };
      case RESOURCES_ERROR:
          return {
            ...state,
            errorMessage: action.payload
          };
      case RESOURCES_SET_DATA:
          return {
            ...state,
            [action.payload.type]: action.payload.value
          };
      default:
        return state;
    }
  }
  