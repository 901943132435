import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FiEdit, FiExternalLink } from "react-icons/fi";
import { connect } from "react-redux";
import { fetchOwnAd } from "../../store/media/actions";
import { fetchProfile } from "../../store/profile/actions";
import { UPLOAD_URL } from "../../config/index";

const Dashboard = (props) => {
  const [loading, setLoading] = useState(true);
  const loadData = async () => {
    if (props.profile.profile) {
      console.log(props.profile.profile);
      if (props.profile.profile.status === "pending") {
        props.history.push("/authorize");
        return;
      }
      if(props.profile.subscription === null){
        props.history.push("/pricing");
        return;
      }else{
        if (!parseInt(props.profile.subscription.status)) {
          props.history.push("/pricing-authorize");
          return;
        }
      }
      await props.fetchOwnAd();
      setLoading(false);
    }
  };
  const reloadData = async () => {
    if (props.profile.profile) {
      if (props.profile.profile.status === "pending") {
        props.history.push("/authorize");
        return;
      }
      if(props.profile.subscription === null){
        props.history.push("/pricing");
        return;
      }else{
        if (!parseInt(props.profile.subscription.status)) {
          props.history.push("/pricing-authorize");
          return;
        }
      }
      await props.fetchOwnAd();
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
  }, [props.profile]);
  useEffect(() => {
    reloadData();
  }, [props.location]);
  useEffect(() => {
    props.fetchProfile()
  }, [])
  if (loading)
    return (
      <div className="container my-5 py-5 mt-5">
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-info" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="container my-5 py-5">
        {props.media?.hasOwnProperty("id") ? (
          <>
            <div className="d-flex flex-column justify-content-between align-items-center my-3 border-bottom pb-3">
              {props.media.status == 0 ? (
                <h3 className="text-warning mb-3">
                  Your post is waiting for admin approval!
                </h3>
              ) : (
                <h3 className="text-success mb-3">Your post is live!</h3>
              )}
              <div className="h3">{props.media.title}</div>
              <div className="d-flex flex-row flex-sm-row">
                <Link
                  to={`/media-upload/${props.media.id}`}
                  className="btn btn-link mr-3 btn-small"
                >
                  <FiEdit className="mr-2" />
                  Edit
                </Link>
                <a
                  href={(props.media?.link?.indexOf('://') === -1) ? 'http://' + props.media?.link : props.media?.link}
                  target="_blank"
                  className="btn btn-outline-primary  btn-small"
                >
                  <FiExternalLink className="mr-2" />
                  Advertisement Link
                </a>
              </div>
            </div>
            <div className="bg-light p-3 shadow-sm rounded">
              <div className="h5">Landscape mode</div>
              {/* <Banner className="jumbotron main-jumbo bg-transparent"></Banner> */}
              <img
                src={`${UPLOAD_URL}${props.media.landscape_media}`}
                alt="Landscape Image"
                className="w-100 m-auto"
                style={{height: 250}}
              />
              <div className="h5">Potrait mode</div>
              {/* <BannerSQ className="jumbotron main-jumbo-sqare mx-auto"></BannerSQ> */}
              <img
                src={`${UPLOAD_URL}${props.media.portrait_media}`}
                alt="Potrait Image"
                className="w-100 m-auto"
                style={{height: 800}}
              />
            </div>
            <div className="mt-5 ">
              <div className="h3 pb-1 border-bottom mb-3">Analytics</div>
              <div className="bg-light p-3 shadow-sm rounded">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="card shadow-sm bg- mb-3">
                      <div className="h5 border-bottom p-2 ">Clicks</div>
                      <div className="display-4 font-weight-bold color-violet text-center p-3">
                        {props.media?.click}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="card shadow-sm bg- mb-3">
                      <div className="h5 border-bottom p-2 ">Impressions</div>
                      <div className="display-4 font-weight-bold color-violet text-center p-3">
                        {props.media?.impressions}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex flex-column justify-content-between align-items-center my-3 border-bottom pb-3">
              <div className="h2">Create New Ad to start the campaign!</div>
              <Link to="/media-upload">
                <button className="btn btn-gradient btn-lg mt-4">CREATE</button>
              </Link>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center mt-4">
              <span className="text-muted">
                *Your advertisement will be reviewed by admin and after approval
                it will show in the mobile application.
              </span>
            </div>
          </>
        )}
      </div>
    );
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
  media: state.media.media,
});
export default connect(mapStateToProps, {
  fetchOwnAd,
  fetchProfile
})(Dashboard);

const Banner = styled.div`
  background-image: url(/images/bg/banner.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const BannerSQ = styled.div`
  background-image: url(/images/bg/banner2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
