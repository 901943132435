import {
  STUDIES_LOADING,
  STUDIES_SET_DATA,
  STUDIES_ERROR,
} from "./constants";
import { _fetchStudies, _editStudy, _deleteStudy, _createStudy } from "./services";
import { errorParser } from '../../services/apiErrorParser';

/* studies actions */
export function studySetLoading(loading) {
  return {
    type: STUDIES_LOADING,
    payload: loading,
  };
}

export function studySetData(data) {
  return {
    type: STUDIES_SET_DATA,
    payload: data,
  };
}

export function studySetError(error) {
  return {
    type: STUDIES_ERROR,
    payload: error,
  };
}

export const fetchStudies = (params) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(studySetLoading(true));
		_fetchStudies(params).then(async (res) => {
			await dispatch(studySetData(res.studies));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(studySetError(parsedError));
			reject(err)
		}).finally(() => {
			dispatch(studySetLoading(false));
		})
	})
};

export const editStudy = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_editStudy(formData).then(async (res) => {
			await dispatch(fetchStudies({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(studySetError(parsedError));
			reject(err)
		})
	})
};

export const deleteStudy = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_deleteStudy(formData).then(async (res) => {
			await dispatch(fetchStudies({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(studySetError(parsedError));
			reject(err)
		})
	})
};

export const createStudy = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_createStudy(formData).then(async (res) => {
			await dispatch(fetchStudies({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(studySetError(parsedError));
			reject(err)
		})
	})
};