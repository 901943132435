import axios from "axios";
import { BASE_URL } from "../../config";
import { getToken } from "../../utils/authManager";
export function _fetchResources(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}RssFeed/fetchByType?type=${params.type}`, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _editResource(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}RssFeed/changeLink`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _deleteResource(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}RssFeed/deleteFeed`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function _createResource(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}RssFeed/create`, formData, {
        headers: {
          Authorization: getToken()
        }
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}