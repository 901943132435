import React from "react";
import { Switch, Redirect, HashRouter } from "react-router-dom";
import RouteWithLayout from "./components/routeWithLayout/RouteWithLayout";
import MainView from "./layout/main/MainView";
import MinimalView from "./layout/minimal/MinimalView";
import AdminView from "./layout/admin/AdminView";

// imported Screens
import Login from "./views/auth/login/Login";
import Signup from "./views/auth/signup/Signup";
import WaitingAuth from "./views/auth/waitingAuth/WaitingAuth";
import Pricing from "./views/pricing/Pricing";
import PricingAuth from "./views/pricingAuth/PricingAuth";
import MediaUpload from "./views/mediaUpload/MediaUpload";
import Dashboard from "./views/dashboard/Dashboard";

// admin
import UsersAdmin from "./admin/users/UsersAdmin";
import NewUsersAdmin from "./admin/newUsers/NewUsersAdmin";
import Payments from "./admin/payments/Payments.jsx";
import Posts from "./admin/posts/Posts";
import Resources from "./admin/resources/Resources";
import News from "./admin/resources/News/News";
import Podcasts from "./admin/resources/Podcasts/Podcasts";
import Products from "./admin/resources/Products/Products";
import Study from "./admin/resources/Study/Study";
import Webinars from "./admin/resources/Webinars/Webinars";

const Routes = () => {
  return (
    <HashRouter>
      <Switch>
      {/* <Redirect from="/" to="/login" /> */}
      <RouteWithLayout
        exact
        path="/"
        component={Login}
        layout={MinimalView}
        access="public"
        restricted={true}
      />
      <RouteWithLayout
        exact
        path="/signup"
        component={Signup}
        layout={MinimalView}
        access="public"
        restricted={true}
      />
      <RouteWithLayout
        exact
        path="/authorize"
        component={WaitingAuth}
        layout={MinimalView}
        access="private"
        scope="subscriber"
      />
      <RouteWithLayout
        exact
        path="/pricing"
        component={Pricing}
        layout={MainView}
        access="private"
        scope="subscriber"
      />
      <RouteWithLayout
        exact
        path="/pricing-authorize"
        component={PricingAuth}
        layout={MinimalView}
        access="private"
        scope="subscriber"
      />
      <RouteWithLayout
        exact
        path="/media-upload/:id?"
        component={MediaUpload}
        layout={MainView}
        access="private"
        scope="subscriber"
      />
      <RouteWithLayout
        exact
        path="/dashboard"
        component={Dashboard}
        layout={MainView}
        access="private"
        scope="subscriber"
      />
       <RouteWithLayout
        exact
        path="/resources"
        component={Resources}
        layout={MainView}
        access="private"
        scope="subscriber"
      />
      <RouteWithLayout
        exact
        path="/resources/news"
        component={News}
        layout={MainView}
        access="private"
        scope="subscriber"
      />
       <RouteWithLayout
        exact
        path="/resources/podcasts"
        component={Podcasts}
        layout={MainView}
        access="private"
        scope="subscriber"
      />
      <RouteWithLayout
        exact
        path="/resources/products"
        component={Products}
        layout={MainView}
        access="private"
        scope="subscriber"
      />
      <RouteWithLayout
        exact
        path="/resources/study"
        component={Study}
        layout={MainView}
        access="private"
        scope="subscriber"
      />
      <RouteWithLayout
        exact
        path="/resources/webinars"
        component={Webinars}
        layout={MainView}
        access="private"
        scope="subscriber"
      />
      {/* admin */}
      <RouteWithLayout
        exact
        path="/admin/users"
        component={UsersAdmin}
        layout={AdminView}
        access="private"
        scope="admin"
      />
      <RouteWithLayout
        exact
        path="/admin/new-users"
        component={NewUsersAdmin}
        layout={AdminView}
        access="private"
        scope="admin"
      />
      <RouteWithLayout
        exact
        path="/admin/payments"
        component={Payments}
        layout={AdminView}
        access="private"
        scope="admin"
      />
      <RouteWithLayout
        exact
        path="/admin/posts"
        component={Posts}
        layout={AdminView}
        access="private"
        scope="admin"
      />
      <RouteWithLayout
        exact
        path="/admin/resources"
        component={Resources}
        layout={AdminView}
        access="private"
        scope="admin"
      />
      <RouteWithLayout
        exact
        path="/admin/resources/news"
        component={News}
        layout={AdminView}
        access="private"
        scope="admin"
      />
       <RouteWithLayout
        exact
        path="/admin/resources/podcasts"
        component={Podcasts}
        layout={AdminView}
        access="private"
        scope="admin"
      />
      <RouteWithLayout
        exact
        path="/admin/resources/products"
        component={Products}
        layout={AdminView}
        access="private"
        scope="admin"
      />
      <RouteWithLayout
        exact
        path="/admin/resources/study"
        component={Study}
        layout={AdminView}
        access="private"
        scope="admin"
      />
      <RouteWithLayout
        exact
        path="/admin/resources/webinars"
        component={Webinars}
        layout={AdminView}
        access="private"
        scope="admin"
      />
    </Switch>
    </HashRouter>
  );
};
export default Routes;
