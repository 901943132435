import {
  PRODUCTS_LOADING,
  PRODUCTS_SET_DATA,
  PRODUCTS_ERROR,
} from "./constants";
import { _fetchProducts, _editProduct, _deleteProduct, _createProduct } from "./services";
import { errorParser } from '../../services/apiErrorParser';

/* products actions */
export function productSetLoading(loading) {
  return {
    type: PRODUCTS_LOADING,
    payload: loading,
  };
}

export function productSetData(data) {
  return {
    type: PRODUCTS_SET_DATA,
    payload: data,
  };
}

export function productSetError(error) {
  return {
    type: PRODUCTS_ERROR,
    payload: error,
  };
}

export const fetchProducts = (params) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(productSetLoading(true));
		_fetchProducts(params).then(async (res) => {
			await dispatch(productSetData(res.products));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(productSetError(parsedError));
			reject(err)
		}).finally(() => {
			dispatch(productSetLoading(false));
		})
	})
};

export const editProduct = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_editProduct(formData).then(async (res) => {
			await dispatch(fetchProducts({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(productSetError(parsedError));
			reject(err)
		})
	})
};

export const deleteProduct = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_deleteProduct(formData).then(async (res) => {
			await dispatch(fetchProducts({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(productSetError(parsedError));
			reject(err)
		})
	})
};

export const createProduct = (formData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		_createProduct(formData).then(async (res) => {
			await dispatch(fetchProducts({type: formData.type}));
			resolve(res);
		}).catch((err) => {
			const parsedError = errorParser.parseError(err)
			dispatch(productSetError(parsedError));
			reject(err)
		})
	})
};